import React from 'react';
import styled from '@mui/material/styles/styled';
import Box from '@mui/material/Box';

import { FormStep } from 'src/types/form-step';
import { useAppState } from 'src/Context';
import { submitForm } from 'src/api';
import { analytics } from 'src/utils/analytics';

import StepOne from './StepOne';
import Final from './Final';
import { pick } from 'lodash';

const ContentBox = styled(Box, {
  label: 'ContentBox',
})(() => ({
  margin: '0 auto',
  maxWidth: '495px',
  '@media (max-width: 600px)': {
    maxWidth: '100%',
  },
}));

const ContainerBox = styled(Box, { label: 'ContainerBox', shouldForwardProp: (prop) => prop !== 'isFinal' })<{
  isFinal: boolean;
}>(({ isFinal }) => ({
  display: isFinal ? 'flex' : 'block',
  flexGrow: 1,
  padding: '71px 129px 105px 135px',
  '@media (max-width: 600px)': {
    padding: '54px 2.0rem 53px',
  },
}));

const FormSteps = () => {
  const { form } = useAppState();
  const [step, setStep] = React.useState<FormStep>(FormStep.STEP_1);
  const [submission, setSubmission] = React.useState(false);
  const currentStepRef = React.useRef<FormStep>(FormStep.STEP_1);

  const renderStep = () => {
    switch (step) {
      case FormStep.STEP_1:
        return (
          <StepOne
            onNext={() => {
              setSubmission(true);
            }}
          />
        );

      case FormStep.FINAL:
        return <Final />;

      default:
        return null;
    }
  };

  React.useEffect(() => {
    if (submission) {
      submitForm({
        soldier: { name: form.stepOne.soldierName },
        memory: { ...pick(form.stepOne, ['story', 'photos']), confirmation: form.stepOne.confirmation },
        contact: { ...pick(form.stepOne, ['email', 'name']), confirmation: form.stepOne.confirmation },
      });
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
      window.parent.postMessage(
        {
          sender: 'FORM_WIDGET',
          type: 'step-changed',
        },
        '*',
      );

      setStep(FormStep.FINAL);
      analytics.gtag.event('Send Recommendation');
    }
  }, [submission, form]);

  React.useEffect(() => {
    // scroll to top when step changes (smoothly)

    if (currentStepRef.current !== step) {
    }
    currentStepRef.current = step;
  }, [step]);

  return (
    <ContainerBox isFinal={step === FormStep.FINAL}>
      <ContentBox>{renderStep()}</ContentBox>
    </ContainerBox>
  );
};

export default FormSteps;
