import { api as config } from '@api/utils/api-request';

const API_HOST_MAP: Record<string, string> = {
  local: `http://localhost:${process.env.API_PORT}`,
  development: `https://${process.env.PROJECT_ID}.firebaseapp.com`,
  production: `https://id2024form.mako.co.il`,
};
export const API_HOST = API_HOST_MAP[`${process.env.ENV}` || `https://${process.env.PROJECT_ID}.firebaseapp.com`];

export const api = config(API_HOST);
