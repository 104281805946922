import React from 'react';
import * as yup from 'yup';
import { Formik, Form } from 'formik';

import styled from '@mui/material/styles/styled';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useMediaQuery } from '@mui/material';

import { TextField, Button, Checkbox } from 'src/components/form';
import { useAppDispatch, useAppState } from 'src/Context';

import PhotoUploader, { SlotData } from './PhotoUploader';

import logo from './assets/logo.svg';

const Container = styled(Box, { label: 'Container' })(() => ({
  position: 'relative',
}));

const Logo = styled('img', { label: 'Logo' })(() => ({
  display: 'block',
  width: '100%',
  marginBottom: '20px',
  '@media (max-width: 600px)': {
    maxWidth: '299px',
  },
}));

const ButtonsContainer = styled(Box, { label: 'ButtonsContainer' })(({ theme }) => ({
  display: 'flex',
  width: '100%',
  justifyContent: 'space-between',
  flexDirection: 'row-reverse',
  marginTop: '24px',

  [theme.breakpoints.down('md')]: {
    '& > div': {
      width: '100%',

      '& > button': {
        width: '100%',
      },
    },
  },
}));

export interface FormValues {
  name: string;
  email: string;
  soldierName: string;
  story: string;
  photos: SlotData[];
  confirmation: boolean;
}

interface Props {
  onNext: () => void;
}

const subtitle =
  'כתבו לנו על ההתארגנות הפרטית או הציבורית שחיממה את ליבכם השנה ושינתה, ולו במעט, את פניה של המדינה בשנה הקשה הזאת.&nbsp;צרפו גם תמונה אם תרצו. הסיפורים יתפרסמו בערב יום העצמאות 2024, <b>ולתפארת מדינת ישראל!</b>';

const StepOne = (props: Props) => {
  const { config, form } = useAppState();
  const dispatch = useAppDispatch();
  const [slots, setSlots] = React.useState<SlotData[]>(form.stepOne.photos);

  console.info(slots);
  const { clientForm: texts } = config.texts;
  const isMobile = useMediaQuery('(max-width: 600px)');

  const validationSchema = React.useMemo(() => {
    return yup.object().shape({
      name: yup.string().test('validator', function (input) {
        if (input) {
          const splittedName = input.split(' ');

          if (input.trim() === '') {
            return this.createError({ path: this.path, message: texts.validation.stepOne.soldierName.required });
          } else if (splittedName.length < 2 || splittedName.some((name) => name.length < 2)) {
            return this.createError({ path: this.path, message: texts.validation.stepOne.soldierName.invalidValue });
          }
          return true;
        }
        return this.createError({ path: this.path, message: texts.validation.stepOne.soldierName.required });
      }),
      email: yup
        .string()
        .email(texts.validation.stepOne.email.invalidValue)
        .required(texts.validation.stepOne.email.required),
      soldierName: yup.string().test('validator', function (input) {
        if (input) {
          const splittedName = input.split(' ');

          if (input.trim() === '') {
            return this.createError({ path: this.path, message: texts.validation.stepOne.soldierName.required });
          } else if (splittedName.length < 2 || splittedName.some((name) => name.length < 2)) {
            return this.createError({ path: this.path, message: texts.validation.stepOne.soldierName.invalidValue });
          }
          return true;
        }
        return this.createError({ path: this.path, message: texts.validation.stepOne.soldierName.required });
      }),
      story: yup.string().required(texts.validation.required),
      confirmation: yup.bool().oneOf([true], texts.validation.stepOne.confirmation.required),
    });
  }, [config]);

  const onSubmit = (values: FormValues) => {
    dispatch({ type: 'UPDATE_FORM_DATA', payload: { target: 'stepOne', data: { ...values, photos: slots } } });
    props.onNext();
  };

  const onPhotoUploaderChange = (slots: SlotData[]) => {
    setSlots(slots);
  };

  return (
    <Container>
      <Logo src={logo} />
      <Typography
        variant="stepSubTitle"
        mb={isMobile ? '30px' : '44px'}
        component="p"
        fontSize="18px"
        lineHeight="24px"
        dangerouslySetInnerHTML={{ __html: subtitle }}
      ></Typography>
      <Box>
        <Formik initialValues={form.stepOne} validateOnBlur validationSchema={validationSchema} onSubmit={onSubmit}>
          <Form>
            <TextField name="name" label={texts.stepOne.fields.name.label} placeholder={''} />

            <TextField name="email" label={texts.stepOne.fields.email.label} placeholder={''} />

            <TextField name="soldierName" label={texts.stepOne.fields.soldierName.label} placeholder={''} />

            <TextField
              name="story"
              multiline
              fullWidth
              rows={10}
              maxChars={400}
              label={texts.stepOne.fields.story.label}
              placeholder={''}
            />

            <PhotoUploader maxAmount={3} slots={slots} onChange={onPhotoUploaderChange} />

            <Checkbox
              name="confirmation"
              label={texts.stepOne.fields.confirmation.label}
              defaultChecked={form.stepOne.confirmation}
            />

            <ButtonsContainer>
              <Button type="submit" primary label={texts.sendBtn} />
            </ButtonsContainer>
          </Form>
        </Formik>
      </Box>
    </Container>
  );
};

export default StepOne;
