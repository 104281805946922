import React from 'react';
import styled from '@mui/material/styles/styled';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useMediaQuery } from '@mui/material';
import logo from './assets/logo.svg';

import { useAppState } from 'src/Context';

const Container = styled(Box, { label: 'Container' })(() => ({
  position: 'relative',
  display: 'flex',
  height: '100%',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'flex-start',
  paddingTop: '100px',
}));

const Logo = styled('img', { label: 'Logo' })(() => ({
  display: 'block',
  width: '100%',
  maxWidth: '475px',
  marginBottom: '54px',
  '@media (max-width: 600px)': {
    maxWidth: '300px',
  },
}));

const StepThree = () => {
  const { config } = useAppState();
  const { clientForm: texts } = config.texts;
  const isMobile = useMediaQuery('(max-width: 600px)');
  return (
    <Container>
      <Logo src={logo} />
      <Typography
        sx={{
          display: 'block',
          fontFamily: 'Yonit-Bold',
          fontSize: isMobile ? '34px' : '46px',
          lineHeight: isMobile ? '46px' : '50px',
          textAlign: 'center',
          mt: '0px',
          mb: '19px',
        }}
        component="p"
      >
        {texts.final.title}
      </Typography>
      <Typography
        sx={{
          maxWidth: isMobile ? '290px' : 'auto',
          fontSize: isMobile ? '22px' : '29px',
          lineHeight: isMobile ? '28.46px' : '33px',
          textAlign: 'center',
        }}
        component="p"
        dangerouslySetInnerHTML={{ __html: texts.final.subTitle }}
      >
        {/* {texts.final.subTitle} */}
      </Typography>
    </Container>
  );
};

export default StepThree;
